.details {
  font-size: var(--mantine-font-size-sm);
  /* background: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-6)
  ); */
  /* background-color: "red"; */
  background: "red";
}

.label {
  width: rem(130px);
}
.redRow {
  color: var(--mantine-color-blue-2);
  background-color: light-dark(
    color-mix(
      in srgb,
      var(--mantine-color-red-6) 10%,
      var(--mantine-color-white)
    ),
    color-mix(
      in srgb,
      var(--mantine-color-blue-6) 30%,
      var(--mantine-color-black)
    )
  );
}

.testClass {
  background-color: "red";
}
